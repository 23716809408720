@import url(https://fonts.googleapis.com/css?family=Muli:300i,400,600,700);
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

article {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Muli"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  color: black;
}

h2 {
  color: black;
}

h3 {
  color: black;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #494c4e;
  font-family: Muli;
}

.app {
  margin: 0 auto 0 auto;
  min-width: 700px;
  max-width: 880px;
}

.header {
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #494c4e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 15px 0 15px 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.page-body {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0 auto;
  margin-bottom: 100px;
  max-height: 9in;
}

.header-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-right img {
  margin-bottom: 10px;
}

.header-right a {
  text-decoration: none;
}

.program-name {
  color: #0e7cbe;
  font-family: Muli;
  font-size: 19px;
  font-weight: 700;
  line-height: 5px;
  text-align: left;
}

.program-date {
  color: #494c4e;
  font-family: Muli;
  font-size: 15px;
  font-weight: 400;
  line-height: 1px;
  text-align: left;
}

.column {
  flex-direction: column;
  -webkit-column-width: inherit;
     -moz-column-width: inherit;
          column-width: inherit;
  width: 400px;
  color: white;
  flex: 1 1;
  padding: 20px;
  margin: 0 auto;
  border-style: solid;
  border-width: 0 1.5px 0 0;
  border-color: #494c4e;
}

.column-right {
  flex-direction: column;
  -webkit-column-width: inherit;
     -moz-column-width: inherit;
          column-width: inherit;
  width: 400px;
  color: white;
  flex: 1 1;
  padding: 20px;
}

.day-title {
  color: #0e7cbe;
  font-family: Muli;
  font-size: 17px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-bottom: 30px;
}

.breakout-day {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
}

.breakout-day > div {
  color: #0e7cbe;
  font-family: Muli;
  font-size: 17px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin: 0;
}

.breakout-day div:first-child {
  margin-bottom: 10px;
  margin-right: 5px;
}

.breakout-title {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.breakout-time {
  color: #494c4e;
  font-family: Muli;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 0;
  margin-bottom: 5px;
}

.breakout-room {
  color: #494c4e;
  font-family: Muli;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 0;
  white-space: nowrap;
}

.breakout-event {
  margin: 0;
  margin-bottom: 15px;
  max-width: 150px;
}

.breakout-body {
  height: 9in;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
}

@media print {
  @page {
    size: 8.5in 11in;
  }

  .app {
    margin: auto 0 auto 20px;
  }

  .header {
    page-break-before: always;
  }
}

.hard-coded-urls, .urls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: sans-serif;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
}

.hard-coded-urls {
  align-items: flex-end;
}


.split-container {
  border-style: solid;
  border-width: 2px 0 2px 0;
  border-color: #494c4e;
  justify-content: center;
  display: flex;
  color: black;
  margin: -2px auto;
}

.container {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #494c4e;
  padding: 10px;
  width: 240px;
}

.container-two-rows {
  padding: 10px 20px;
  width: 240px;
}

.container-three-rows {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #494c4e;
  padding: 10px;
  width: 160px;
}

.container-padding {
  padding: 10px;
  width: 160px;
}

.event-description {
  color: #494c4e;
  font-family: Muli;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.group-title {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  text-decoration: underline;
  margin: 0 auto;
}

.group-title-no-padding {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  text-decoration: underline;
  margin: 0 auto;
}

.split-event {
  padding: 0;
  padding-bottom: 10px;
  min-width: 70px;
}

.split-event-time {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0 auto;
}

.split-event-time-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0 auto;
}

.split-event-row-left {
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 70px;
}

.split-inner-container {
  border-style: solid;
  border-width: 0 2px 0 0;
  border-color: #494c4e;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.split-event-title {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0 auto;
  min-width: 70px;
}

.split-event-title-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0 auto;
}


.event {
  display: flex;
  color: white;
  margin: 5px 0 0 0;
  padding: 5px 0 8px 0;
}

.event-break {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  margin: 5px 0 0 0;
  padding: 0 0 10px 0;
}

.event-title {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.event-title-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.event-time {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.event-time-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.event-description {
  color: #494c4e;
  font-family: Muli;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 0px;
}

.event-row-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event-row-left {
  min-width: 160px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

